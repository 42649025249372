<script>
  import MyFooter from "@/components/MyFooter"
  import api from "@/api"
  import EventBus from "@/eventbus"

  export default {
    name: "home",
    components: {
      MyFooter
    },
    props: {screenWidth:Number},  
    data: function() {
      return {
        mails: []
      }
    },
    created() {
      // this.$store.dispatch("cleanNavigationHistory")
      this.$store.dispatch("resetQuestionnaireState")

      this.$store.dispatch("pushPage", this.$route.name)
      this.$store.dispatch("disableBackButton")

      api.getMails().then((response) => {
        this.mails = response.data.addresses
      }).catch(() => {
        EventBus.$emit("errorMessage", "Impossibile reperire le tue e-mail")
      })
    },
    methods: {
      startQuestionnaire() {
        this.$store.dispatch("setQuestionnaireState", "structures")
        this.$router.replace({ name: "questionnaire"})
      } 
    }
  }
</script>

<template>

  <div class="my-is-under-navbar">
    <div class="section block">
      <div class="columns is-vcentered is-centered is-multiline is-mobile">
        <!--<div class="column is-3-desktop is-offset-2-desktop is-4-tablet is-offset-1-tablet is-10-mobile is-offset-1-mobile">
          <figure class="image is-128x128 is-squared">
            <img :src="require(`@/assets/images/256x256.png`)" alt="PLACEHOLDER">
          </figure>
        </div>-->

        <div class="column is-2 is-12-mobile is-flex is-justify-content-center" v-if="screenWidth > 768">
          <figure class="image is-128x128 is-squared ">
            <img class="" :src="require(`@/assets/images/logo-256.png`)" alt="TRAC19 logo">
          </figure>
        </div>
        <div class="column is-4-desktop is-8-tablet is-12-mobile">
          <!--<p class="title is-2 has-text-weight-normal">
            {{ $t("extendedName") }}
          </p>
          <p class="subtitle is-size-4">
            Compila il questionario, ottieni il codice di accesso ed entra dove vuoi in totale sicurezza.
          </p>-->
          <p class="subtitle is-size-3">
            1. Compila il questionario
          </p>
          <p class="subtitle is-size-3">
            2. Ottieni il codice di accesso
          </p>
          <p class="subtitle is-size-3">
            3. Entra dove vuoi in totale sicurezza
          </p>
        </div>
      </div>
    </div>

    <div class="my-5">
      <div class="block hero is-info">
        <div class="hero-body">
          <div class="columns is-vcentered is-centered is-multiline">
            <div class="column is-narrow has-text-centered">
              <div class="button is-large is-white" v-on:click="startQuestionnaire">{{ $t("startForm") }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="block hero" v-if="mails.length > 0">
        <div class="hero-body">
          <div class="columns is-vcentered is-centered is-multiline">
            <div class="column is-narrow has-text-centered">
              <p class="is-size-5"> Vuoi aggiornare i tuoi indirizzi e-mail? <a v-on:click="$router.replace({name:'mail'})" style="display:inline-block"> Clicca qui </a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MyFooter />
  </div>
</template>

<i18n>
{
  "it": {
    "extendedName": "TRiage Against Covid-19",
    "startForm": "Nuovo questionario",
    "userData": "Le tue risorse",
    "seePrevForms": "Questionari compilati",
    "seeMailAddresses": "Gestisci E-mail"
  }
}
</i18n>

<style>



</style>
