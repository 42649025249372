<template>
  <div class="footer">
    <div class="columns is-multiline is-centered has-text-centered">
      <div class="column is-4 is-12-mobile has-text-centered">
        <div>
          <figure class="image is-inline-block is-24x24">
            <img :src="require('@/assets/images/logo-64.png')">
          </figure>
          &nbsp;
          <span class="title is-3">{{$t("appName")}}</span>
        </div>
        <div>
          <p>Powered by AIVOLUTION and IRST</p>
        </div>
      </div>
      <div class="column is-4  ">
        <span class="title is-6">
          {{ $t("info") }}
        </span>
        <ul class="is-2 ">
          <li><a href="https://google.com"  target="_blank">{{ $t("privacy") }}</a></li>
          <li><a href="#" v-on:click="$router.replace({name: 'help-faq'})">{{ $t("questions") }}</a></li>
        </ul>
      </div>
      <div class="column is-4 ">
        <span class="title is-6">
          {{ $t("contacts") }}
        </span>
        <ul clsas="is-2">
          <li>assistenza@trac19.it</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "appName": "TRAC19",
    "info": "Informazioni",
    "contacts": "Contatti",
    "privacy": "Informativa Privacy",
    "questions": "Domande frequenti"
  }
}
</i18n>

<script>
  export default {
  }
</script>

<style lang="scss">
  .footer {
    border-top: 10px solid #f3f3f3;
  }
</style>
